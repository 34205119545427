import { ref } from '@vue/composition-api'

import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableRichMenuLink() {
  const refRichMenuLinkTable = ref(null)

  const msgRichMenuLink = {
    errorfetch: t2('Error fetching {module} list', { module: t('Rich Menu Link') }),
    no: t('No.'),
    user_id: `${t('Line')} » ${t('User Id')}`,
    picture_url: `${t('Line')} » ${t('Image')}`,
    display_name: `${t('Line')} » ${t('Display Name')}`,
    richmenu_type: `${t('Rich Menu Type')}`,
    status: t('Status'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'no', sortable: false, label: msgRichMenuLink.no },
    { key: 'user_id', sortable: false, label: msgRichMenuLink.user_id },
    { key: 'picture_url', sortable: false, label: msgRichMenuLink.picture_url },
    { key: 'display_name', sortable: false, label: msgRichMenuLink.display_name },
    { key: 'richmenu_type', sortable: false, label: msgRichMenuLink.richmenu_type },
    { key: 'status', label: msgRichMenuLink.status },
  ]

  const refetchData = () => {
    refRichMenuLinkTable.value.refresh()
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    tableColumns,
    refRichMenuLinkTable,

    refetchData,
    textFirstUpper,
  }
}
